import React from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { CALEDNER_GRAY_ICON } from "../../../../constants";

function EventCard({
	imageSrc,
	eventName,
	eventDescription,
	isPastEvent = false,
	isPremium = false,
	displayDate,
	onEventClick,
	showActions = true,
	boxShadow = "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
	isSelected = false,
}) {
	const fontColor = isPastEvent ? { color: (theme) => theme.palette.sos.gray400 } : {};

	return (
		<Box
			position="relative"
			width="400px"
			sx={(theme) => ({
				display: "flex",
				flexDirection: "column",
				rowGap: "28px",
				p: 4,
				borderRadius: "24px",
				backgroundColor: theme.palette.secondary.white,
				boxShadow: boxShadow,
				border: isSelected ? `4px solid ${theme.palette.primary.main}` : `4px solid #ffff`,
				"&:hover": {
					cursor: "pointer",
					".event-register-btn": { backgroundColor: theme.palette.secondary.dark, color: theme.palette.secondary.white },
				},
			})}
			onClick={onEventClick}
		>
			<Box
				sx={{
					height: "261px",
					alignSelf: "stretch",
					borderRadius: "24px",
					overflow: "hidden",
				}}
			>
				<Box
					component={"img"}
					src={imageSrc}
					sx={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
				/>
			</Box>
			<Box display={"flex"} justifyContent="space-between" alignItems={"center"} flexWrap={"wrap"} gap={1}>
				<Typography
					variant="Text/sm/Semibold"
					sx={{
						...fontColor,
						lineHeight: "32px",
						overflow: "hidden",
						display: "-webkit-box",
						WebkitBoxOrient: "vertical",
						WebkitLineClamp: 1,
						lineHeight: "1.2em",
						maxHeight: "2.4em",
						maxWidth: "300px",
						width: 1,
					}}
				>
					{eventName}
				</Typography>

				{isPremium && (
					<Chip
						sx={(theme) => ({ backgroundColor: theme.palette.sos.gray100, "& .MuiChip-label": { px: "10px" }, visibility: !isPremium && "hidden" })}
						label={
							<Typography variant="Text/xs/Regular" sx={(theme) => ({ color: theme.palette.primary.main })}>
								Premium
							</Typography>
						}
					/>
				)}
			</Box>
			<Typography
				variant="Text/sm/Regular"
				component={"p"}
				sx={{
					...fontColor,
					overflow: "hidden",
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: 2,
					lineHeight: "1.2em",
					maxHeight: "2.4em",
					height: "40px",
					"& p": { margin: 0 },
					"& ul": { marginBlockStart: 0, marginBlockEnd: 0 },
				}}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: eventDescription,
					}}
				/>
			</Typography>
			<Box display={"flex"} alignItems={"center"} columnGap={1}>
				<Box component={"img"} src={CALEDNER_GRAY_ICON} alt="event-icon" />
				<Typography variant="Text/sm/Semibold" sx={(theme) => ({ color: theme.palette.sos.gray400 })}>
					{displayDate}
				</Typography>
			</Box>
			{showActions && (
				<>
					{" "}
					{!isPastEvent ? (
						<Button variant="DS1" color={"gray"} className="event-register-btn">
							Register Now
						</Button>
					) : (
						<Button variant="DS1" color={"clear"}>
							Registration Closed
						</Button>
					)}
				</>
			)}
		</Box>
	);
}

export default EventCard;
