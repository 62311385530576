import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import { setCommonDialogConfirmationData } from "../../../modules/actions";
import services from "../../../services";
import PaymentPlanDialog from "../../PaymentPlanDialog";
import { getFormattedDate } from "../../../utils/date";
import { GREY_EDIT_ICON_V2 } from "../../../constants";
import { isEduEmail } from "../../../utils/commonFunctions";

function EventDetailsCard({ eventDetails, loading, isEdit = false, openUpdateEventModel = () => {} }) {
	const dispatch = useDispatch();
	const { isPremiumPlanAccess, userType, isAdmin, userId, email, partnerCode } = useSelector((store) => store?.auth);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const { utmCampaign } = useSelector((store) => store.utmParams);

	const isPastEvent = () => {
		const currentDate = new Date().getTime();
		let check = new Date(eventDetails?.deadlineDate);
		if (check < currentDate) {
			return true;
		} else {
			return false;
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const actionApiCall = () => {
		services
			.updateStreakActionApi({
				actionType: "EVENT",
				actionOnId: userId,
				actionOnType: "EVENT",
				metaData: null,
			})
			.then()
			.catch();
	};

	const onEventClick = () => {
		let isPitchExpand = false;
		let isSVGExpand = false;
		if (eventDetails?.title === "StartupOS Pitch Day Event") {
			isPitchExpand = true;
			isSVGExpand = false;
		}

		handleMixPanel("Register Now Clicked", { subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed", user_type: userType });
		handleMixPanel("Event Clicked", {
			"Event Name": eventDetails?.title,
			"Is Premium": eventDetails?.isPremiumEvent,
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});
		if (
			eventDetails?.isPremiumEvent === true &&
			!isPremiumPlanAccess &&
			!isEduEmail(email) &&
			!isAdmin &&
			eventDetails?.uuid !== "genai" &&
			utmCampaign &&
			utmCampaign !== "gen-ai"
		) {
			setShowPlanModal(true);
		} else {
			actionApiCall();
			if (eventDetails?.isPitchEvent) {
				dispatch(
					setCommonDialogConfirmationData({
						applyToPitchOpen: true,
						isPitchExpand,
						isSVGExpand,
					})
				);
			} else {
				window.open(eventDetails?.redirectLink, "_blank");
			}
		}
	};

	const getEventButtonName = () => {
		if (eventDetails?.uuid === "genai" && utmCampaign && utmCampaign === "gen-ai") {
			return "Register Now";
		} else {
			/*
				this is a premium event
				user does not have a premium plan
				user is not an edu
				user is not an admin
				user origin is sos
			*/
			if (eventDetails?.isPremiumEvent === true && !isPremiumPlanAccess && !isEduEmail(email) && !isAdmin && partnerCode === "sos") {
				return "Upgrade to Premium";
			} else {
				return "Register Now";
			}
		}
	};

	return (
		<>
			{loading ? (
				<Skeleton variant="rectangular" height={400} width={"100%"} sx={(theme) => ({ borderRadius: theme.shape.standard5.borderRadius })} />
			) : eventDetails ? (
				<Box
					sx={(theme) => ({
						p: 4,
						borderRadius: theme.shape.standard5.borderRadius,
						backgroundColor: theme.palette.secondary.white,
					})}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
							<Stack sx={{ width: 1 }}>
								<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
									<Typography variant="Text/md/Semibold" component={"p"} sx={{ maxWidth: "300px" }}>
										{eventDetails?.title}
									</Typography>

									{isEdit ? (
										<Button
											startIcon={<Box component={"img"} src={GREY_EDIT_ICON_V2} alt="edit" />}
											variant="DS1"
											color={"gray"}
											onClick={openUpdateEventModel}
										>
											Edit Event
										</Button>
									) : (
										<>
											{" "}
											{!isPastEvent() ? (
												<Button variant="DS1" color={"gray"} className="event-register-btn" onClick={() => onEventClick()}>
													{getEventButtonName()}
												</Button>
											) : (
												<Button variant="DS1" color={"clear"}>
													Registration Closed
												</Button>
											)}
										</>
									)}
								</Box>

								<Box display={"flex"} gap={1} alignItems={"center"}>
									{eventDetails?.isPrivate && eventDetails?.eventAccesses?.length > 0 && (
										<Chip
											sx={(theme) => ({ backgroundColor: theme.palette.error.light, "& .MuiChip-label": { px: "10px" } })}
											label={
												<Typography variant="Text/xs/Regular" sx={(theme) => ({ color: theme.palette.error.dark })}>
													Private Event
												</Typography>
											}
										/>
									)}
									<Chip
										sx={{ backgroundColor: (theme) => theme.palette.secondary.white, "& .MuiChip-label": { px: "10px" } }}
										variant="outlined"
										label={
											<Typography
												variant="Text/xs/Regular"
												sx={(theme) => ({ color: theme.palette.secondary.black, borderColor: theme.palette.secondary.grey })}
											>
												{getFormattedDate(eventDetails?.eventDate, "MMMM DD, YYYY")}
											</Typography>
										}
									/>
									{eventDetails?.isPremiumEvent && (
										<Chip
											sx={{
												backgroundColor: "rgba(242, 239, 255, 1)",
												"& .MuiChip-label": { px: "10px" },
												visibility: !eventDetails?.isPremiumEvent && "hidden",
											}}
											label={
												<Typography variant="Text/xs/Regular" sx={{ color: (theme) => theme.palette.primary.main }}>
													Premium
												</Typography>
											}
										/>
									)}
								</Box>
								<Box sx={{ mt: 2 }}>
									<Typography variant="Text/sm/Regular" sx={{ "& p": { margin: 0 }, "& ul": { marginBlockStart: 0, marginBlockEnd: 0 } }}>
										<span
											dangerouslySetInnerHTML={{
												__html: eventDetails?.description,
											}}
										/>
									</Typography>
								</Box>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
							<Box
								sx={{
									alignSelf: "stretch",
									borderRadius: "24px",
									overflow: "hidden",
									ml: "auto",
								}}
							>
								<Box
									component={"img"}
									src={eventDetails?.imageUrl}
									sx={{
										width: "100%",
										height: "100%",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
					{showPlanModal && <PaymentPlanDialog open={showPlanModal} isRedirect={false} onClose={handleClosePlanModal} />}
				</Box>
			) : (
				<Typography variant="Text/md/Semibold" sx={{ textAlign: "center", mt: 3 }} component={"p"}>
					No Events Found
				</Typography>
			)}
		</>
	);
}

export default EventDetailsCard;
