import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography, useTheme, Grid, Skeleton } from "@mui/material";
import { ROCKET_LAUNCH_ICON } from "../../../../../constants";
import HomePowerCard from "../../components/HomePowerCard/HomePowerCard";
import services from "../../../../../services";
import PowerPackDetailModal from "../../../../../components/Investor/Workspace/View/PowerPackDetailModal";
import { generatePath, useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../../constants/routes";
import { handleMixPanel } from "../../../../../utils/mixPanelEventHandling";

function PowerPack() {
	const [packCards, setPackCards] = useState([]);
	const [openPowerPackModal, setOpenPowerPackModal] = useState(false);
	const [packDetail, setPackDetail] = useState({ title: "", desc: "", items: [] });
	const [loading, setLoading] = useState(false);
	const skeletonLoaderArray = [1, 2, 3, 4];
	const partnerCode = useSelector((store) => store?.auth?.partnerCode);

	const history = useHistory();
	useEffect(() => {
		getGroupInfo();
	}, []);

	const handlePowerPackDetail = () => {
		setOpenPowerPackModal(false);
	};
	const getGroupInfo = async () => {
		try {
			setLoading(true);
			const res = await services.getToDosList("powerpacks");
			if (res) {
				setLoading(false);
			}

			setPackCards(res?.data?.data.filter((pack) => pack?.cardStatus !== "COMING_SOON"));

			if (partnerCode === "palm") {
				setPackCards(res?.data?.data.filter((pack) => pack?.uuid === "palmpack"));
			} else if (partnerCode === "techstars") {
				setPackCards(
					res?.data?.data.filter((pack) => 
					  ["perfect-your-pitch-74", "product-fit-10", "go-to-market-fit-36", "problem-solution-fit-65", "customer-fit-92"].includes(pack.uuid)
					)
				  );
			} else {
				setPackCards(
					res?.data?.data.filter((pack) => 
					  !["palmpack", "perfect-your-pitch-74", "product-fit-10", "go-to-market-fit-36", "problem-solution-fit-65", "customer-fit-92"].includes(pack.uuid)
					)
				  );
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getPackDetailInfoById = (packId, packTitle) => {
		handleMixPanel(`PowerPack Clicked`, { "PowerPack ID": packId, "PowerPack Name": packTitle });
		history.push({
			pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERPACK_DETAILS, {
				id: packId,
			}),
		});
	};

	return (
		<Box display="flex" flexDirection={"column"} rowGap={4} alignItems={"center"}>
			<Stack alignItems={"center"}>
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={1}>
					<Box component="img" src={ROCKET_LAUNCH_ICON} sx={{ width: "32px", height: "32px" }} />
					<Typography variant="Text/md/Semibold">Get your startup started using our step-by-step PowerPack program!</Typography>
				</Box>
				<Typography variant="Text/sm/Regular" textAlign="center">
					Validate your great idea, perfect your pitch deck, network with our community, and pitch to investors!
				</Typography>
			</Stack>
			<Box display="flex" flexWrap={"wrap"} justifyContent={"center"} gap={3}>
				{" "}
					{loading 
						? skeletonLoaderArray.map((item) => {
								return <SkeletonCardLoader />;
						})
						: packCards.map((pack) => (
								<HomePowerCard
									key={pack.id}
									variant="secondary"
									uuid={pack.uuid}
									title={pack.title}
									desc={pack?.shortDescription}
									imgSrc={pack.imageUrl}
									isAI={pack?.isAiPowered}
									isPremium={pack?.isPremiumFeature}
									onDetail={() => getPackDetailInfoById(pack.uuid, pack.title)}
								/>
						))
					}
			</Box>
			{/* <Grid container spacing={2}>
				{packCards.map((pack) => (
					<Grid key={pack?.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
						<HomePowerCard
							key={pack.id}
							variant="secondary"
							uuid={pack.uuid}
							title={pack.title}
							imgSrc={pack.imageUrl}
							isAI={pack?.isAiPowered}
							isPremium={pack?.isPremiumFeature}
							onDetail={getPackDetailInfoById}
						/>
					</Grid>
				))}
			</Grid> */}
			<PowerPackDetailModal open={openPowerPackModal} onClose={handlePowerPackDetail} data={packDetail} />
		</Box>
	);
}

export default PowerPack;

export const SkeletonCardLoader = () => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			width="319px"
			sx={{
				gap: 2,
				padding: "32px",
				background: "#fff",
				borderRadius: "24px",
				"&:hover": { cursor: "pointer" },
				boxShadow: "0px 10px 25px 0px #0000000D",
			}}
		>
			{" "}
			<Stack spacing={2}>
				<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "24px" }} />
				<Skeleton animation="wave" variant="rectangular" sx={{ borderRadius: "24px" }} height={200} />
				<Skeleton variant="rectangular" height={50} width={"100%"} sx={{ borderRadius: "24px" }} />
			</Stack>
		</Box>
	);
};
